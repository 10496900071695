const siteTitle = "Aisle Rocket";

let siteUrl = process.env.SITE_URL;
if (process.client) siteUrl = window.location.origin;

export default ({ title, path, description, image }) => {
  let fullTitle = siteTitle;
  if (title !== "Home") fullTitle = `${title} | ${siteTitle}`;

  const url = siteUrl + path;

  const metaData = [
    {
      key: "og:title",
      property: "og:title",
      content: fullTitle,
    },
    {
      key: "og:type",
      property: "og:type",
      content: "website",
    },
    {
      key: "og:url",
      property: "og:url",
      content: url,
    },
    {
      key: "twitter:card",
      property: "twitter:card",
      content: "summary_large_image",
    },
    {
      key: "twitter:title",
      property: "twitter:title",
      content: fullTitle,
    },
    {
      key: "twitter:url",
      property: "twitter:url",
      content: url,
    },
    {
      key: "google-site-verification",
      name: "google-site-verification",
      content: "uHJjZD-IQkN4BSXni6n7dy16Jge9oQ-kQgA0V8o5_RU",
    },
  ];

  if (description) {
    metaData.push({
      key: "og:description",
      property: "og:description",
      content: description,
    });

    metaData.push({
      key: "twitter:description",
      property: "twitter:description",
      content: description,
    });

    metaData.push({
      key: "description",
      name: "description",
      content: description,
    });
  }

  if (image) {
    metaData.push({
      key: "og:image",
      property: "og:image",
      content: `${siteUrl}${image}`,
    });

    metaData.push({
      key: "twitter:image",
      property: "twitter:image",
      content: `${siteUrl}${image}`,
    });
  }

  return {
    title,
    meta: metaData,
  };
};
