//
//
//
//
//
//
//
//
//
//
//
//

import blokProp from "~/helpers/blok-prop";
import metaTags from "~/helpers/meta-tags";
const __sfc_main = {
  head() {
    const imageUrl = this.blok.seo.og_image || this.blok.seo.twitter_image;
    const image = imageUrl ? this.$img(imageUrl) : null;
    return metaTags({
      title: this.blok.seo.title,
      path: this.$route.path,
      description: this.blok.seo.description,
      image
    });
  }

};
__sfc_main.props = blokProp;

__sfc_main.setup = (__props, __ctx) => {
  return {};
};

export default __sfc_main;
